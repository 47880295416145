import React from 'react';
import { Badge, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import '../scss/components/DataTables.scss';
//import Icon from '@mui/material/Icon';
import { toast } from 'react-toastify';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import { AuthHandler } from '../utils/AuthHandler';
import Utils from '../utils/Utils';

function ConfirmQuote() {

  const isAdmin = (AuthHandler.getUserData().role[0] === 'ADMIN');

  const { t } = useTranslation();
  const location = useLocation();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [hasTicket, setHasTicket] = React.useState(isAdmin);
  const [idTicket, setIdTicket] = React.useState(false);
  const [modalId, setModalId] = React.useState('')
  const [issueType, setIssueType] = React.useState('')
  const [issueDescr, setIssueDescr] = React.useState('')

  const showModal = (id) => {
    setModalIsOpen(true);
    setIssueDescr('');
    setIssueType('');
    setModalId(id);
  };

  const hideModal = () => {
    setModalIsOpen(false);
    setIssueDescr('');
    setIssueType('');
    setModalId('');
  };
  const confirmModal = () => {
    if (issueType) {
      // ------------ 
      const userData = AuthHandler.getUserData();

      var finalObject = {
        //quoteId: location.state.quote.id,
        circuitId: modalId,
        issueType: issueType,
        issueDescr: issueDescr,
        issueRequesterName: userData.name + " " + userData.surname,
        issueRequesterEmail: userData.username
      };
      //console.log("crea ticket", finalObject);
      RestService.post(
        {
          url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.newIssueTicket}`,
          body: finalObject
        }
      ).then((res) => {
        if (~~(res.status / 100) === 2) {
          res.json().then((data) => {
            setHasTicket(true);
            setIdTicket(data.body.ticket.id);
            hideModal();
            toast.success(t('NEWQUOTE.NOTIFICATIONS.NotificationTicketSubmitted'));
          });
        } else {
          res.json().then((statusText) => {
            toast.error(
              <>
                {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                <small><i>{statusText.error}</i></small>
              </>,
            );
          });
        }
      });
      // ------------ 
    }
  };

  const downloadQuote = (value) => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getDocument}?id=${value}`
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          toast.warning(`${t('QUOTELIST.NotificationNoDocumentFoundLabel')} ${value}`);
        }
      })
      .then((res) => {
        const blobURL = window.URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `Quote_${value}.pdf`);
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      });
  };

  const generateSites = () => {
    var divsArray = [];
    if (location.state) {
      for (var i = 0; i < location.state.quote.quoteDetails.length; i++) {
        //console.log("49 quoteDetails: ", location.state.quote.quoteDetails);
        var quoteDetails = location.state.quote.quoteDetails[i];
        var comunicaInfo = quoteDetails.comunicaInfo;
        var pricebookInfo = quoteDetails.pricebookInfo;
        var noteInfo = quoteDetails.noteInfo;
        var ticketInfo = quoteDetails.ticketInfo;
        //console.log("55 pricebookInfo: ", pricebookInfo);
        divsArray.push(
          <div className="siteWrapper" key={`container_site-${i}`}>
            <div className="form__Middle_SiteOne mt-5">
              <div className="form__Middle_SiteOne_left p-2 m-1">SITE {i + 1}</div>
              <div className="form__Middle_SiteOne_right m-1">
                <p>{quoteDetails.city}, {quoteDetails.street} ({quoteDetails.streetNumber})</p>
              </div>
            </div>
            <table className="DataTables ol-details-variant">
              <tbody>
                <tr className="dummyTR"><td width="200"></td><td></td></tr>
                <tr>
                  <td>{t('FORMS.PlanLabel')}</td>
                  <td>{pricebookInfo.description}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.FeasibilityLabel')}</td>
                  <td>{pricebookInfo.fattibilita ? t('NEWQUOTE.COVERAGE.Needed') : t('NEWQUOTE.COVERAGE.NotNeeded')}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.DurationLabel')}</td>
                  <td>{pricebookInfo.duration > 0 ? pricebookInfo.duration.toFixed(0) : ''}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.NRCLabel')}</td>
                  <td>{pricebookInfo.nrc > 0 ? pricebookInfo.nrc.toFixed(2) : ''}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.MRCLabel')}</td>
                  <td>{pricebookInfo.mrc > 0 ? pricebookInfo.mrc.toFixed(2) : ''}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.CoveragePlan')}</td>
                  <td>{comunicaInfo.tipoServizioCopertura.descrizione} ({comunicaInfo.tipoServizioCopertura.id})</td>
                </tr>
                <tr>
                  <td>{t('FORMS.StatusLabel')}</td>
                  <td>{comunicaInfo.statoCoperturaServizio.descrizione}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.NetworkType')}</td>
                  <td>{comunicaInfo.statoCoperturaServizio.parametri['Tipo elemento di rete']}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.NetworkName')}</td>
                  <td>{comunicaInfo.statoCoperturaServizio.parametri['LOGGED-Nome elemento di rete']}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );

        if ((!!ticketInfo) && (!!ticketInfo.fields)) {
          var fieldsArray = [];
          ['Stato', 'Data Attivazione', 'Kit Raccolta', 'Service Vlan', 'Customer Vlan', 'User Vlan', 'Username', 'Password', 'Note Esito'].forEach((k) => {
            if (!!ticketInfo.fields[k]) fieldsArray.push(
              <tr key={`container_ticket-${i}-${k}`}><td>{k}</td><td>{ticketInfo.fields[k]}</td></tr>
            );
          });
          divsArray.push(
            <div className="w-75 ms-auto me-0 bg-light" key={`container_ticket-${i}`}>
              <div className="form__Middle_SiteOne bg-white mt-3" >
                <div className="form__Middle_SiteOne p-2" >
                  <button className={`btn btn-sm btn-danger ${hasTicket ? 'd-none disable' : 'd-block'}`} onClick={() => { showModal(ticketInfo.fields['Id']); }}>Issue ticket</button>
                  <button className={`btn btn-sm btn-success ${idTicket ? 'd-block' : 'd-none'} rounded-pill disabled`}>Issue ticket #{idTicket} created</button>
                </div>
                <div className="form__Middle_SiteOne_left p-2 m-1">{t('NEWQUOTE.TICKET.FIELDS')} #{ticketInfo.fields['Id']}</div>
              </div>
              <table className="DataTables ol-details-variant">
                <tbody>
                  <tr className=""><td width="200"></td><td></td></tr>
                  {fieldsArray}
                </tbody>
              </table>
            </div >
          );

        }

        if ((!!noteInfo) && (!!noteInfo.nota)) {
          var notesArray = [];
          for (var k = 0; k < noteInfo.nota.length; k++) {
            var v = noteInfo.nota[k];
            var dt = new Date(v.ts * 1000);
            notesArray.push(
              <tr>
                <td>{dt.toLocaleDateString('it', { year: 'numeric', month: '2-digit', day: '2-digit' })} {dt.toLocaleTimeString('it', { hour: '2-digit', minute: '2-digit' })}</td>
                <td>{v.message}</td>
              </tr>
            )
          }
          divsArray.push(
            <div className="mx-5 px-5" key={`container_note-${i}`}>
              <div className="me-5 pe-5">
                <div className="p-2 mt-3" style={{ backgroundColor: '#f7f7f7' }}>{t('FORMS.CommentsLabel')}</div>
                <table className="DataTables ol-details-variant" style={{ backgroundColor: '#fbfbfb' }}>
                  <tbody>
                    <tr className="dummyTR"><td width="200"></td><td></td></tr>
                    {notesArray}
                  </tbody>
                </table>
              </div>
            </div>
          );
        }

      }
    }
    return divsArray;
  };

  return (
    <Container fluid>
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h4 className="ol-details-heading">
            {t('QUOTELIST.VIEWQUOTE.PageTitle')} {location.state && location.state.quote.client.companyName}
          </h4>
        </Col>
        <Col>
          <Badge pill bg="info">
            {t('FORMS.CRMOpportunityLabel')}
          </Badge>
          <p>{location.state && location.state.quote.crmOpportunityId}</p>
        </Col>
        <Col>
          <Badge pill bg="info">
            {t('FORMS.ExpirationDateLabel')}
          </Badge>
          <p>{location.state && location.state.quote.expairingDate}</p>
        </Col>
        <Col>
          <Badge pill bg="info">
            {t('FORMS.CodeLabel')}
          </Badge>
          <p>{location.state && location.state.quote.code}</p>
        </Col>
      </Row>

      <Row style={{ marginBottom: '15px', backgroundColor: '#f7f7f7', padding: '2rem' }}>
        <h6 className="ol-details-heading">{t('QUOTELIST.VIEWQUOTE.QuoteStatusLabel')}</h6>
        <hr className="styledHR" />
        <Col className="item-center">
          <table className="DataTables ol-details-variant">
            <tbody>
              <tr className="dummyTR"><td width="200"></td><td></td></tr>
              <tr>
                <td>{t('FORMS.StatusLabel')}</td>
                <td>{Utils.QuoteStatus(location.state.quote.status)}</td>
              </tr>
              <tr>
                <td>{t('FORMS.CommentsLabel')}</td>
                <td>
                  {location.state &&
                    (location.state.quote.statusMessage === null
                      ? t('QUOTELIST.VIEWQUOTE.DefaultStatus')
                      : location.state.quote.statusMessage)}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Row style={{ marginBottom: '25px' }}>
        <h6 className="ol-details-heading">{t('QUOTELIST.VIEWQUOTE.QuoteDetailsLabel')}</h6>
        <hr className="styledHR" />
        <Row style={{ marginBottom: '25px' }}>
          <Col>
            <table className="DataTables ol-details-variant">
              <tbody>
                <tr className="dummyTR"><td width="200"></td><td></td></tr>
                <tr>
                  <td>{t('FORMS.CompanyNameLabel')}</td>
                  <td> {location.state && location.state.quote.client.companyName} </td>
                </tr>
                <tr>
                  <td>{t('FORMS.VatLabel')}</td>
                  <td>{location.state && location.state.quote.client.vatNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.AsnPlaceholder')}</td>
                  <td>{location.state && location.state.quote.client.asNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferenceNameLabel')}</td>
                  <td>{location.state && location.state.quote.referenceName}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferencePhoneLabel')}</td>
                  <td>{location.state && location.state.quote.referencePhone}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.LocationLabel')}</td>
                  <td>
                    {location.state && location.state.quote.client.street}{' '}
                    {location.state && location.state.quote.client.streetNumber},{' '}
                    {location.state && location.state.quote.client.city}
                  </td>
                </tr>
                <tr>
                  <td>{t('FORMS.CommentsLabel')}</td>
                  <td>{location.state && location.state.quote.note}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <h6 className="ol-details-heading">{t('QUOTELIST.VIEWQUOTE.QuoteDetailsLabel')} ticket</h6>
        <hr className="styledHR" />
        <Row style={{ marginBottom: '25px' }}>
          <Col>
            <table className="DataTables ol-details-variant">
              <tbody>
                <tr className="dummyTR"><td width="200"></td><td></td></tr>
                <tr>
                  <td>{t('FORMS.CompanyNameLabel')}</td>
                  <td> {location.state && location.state.quote.client.companyName} </td>
                </tr>
                <tr>
                  <td>{t('FORMS.VatLabel')}</td>
                  <td>{location.state && location.state.quote.client.vatNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.AsnPlaceholder')}</td>
                  <td>{location.state && location.state.quote.client.asNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferenceNameLabel')}</td>
                  <td>{location.state && location.state.quote.referenceName}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferencePhoneLabel')}</td>
                  <td>{location.state && location.state.quote.referencePhone}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.LocationLabel')}</td>
                  <td>
                    {location.state && location.state.quote.client.street}{' '}
                    {location.state && location.state.quote.client.streetNumber},{' '}
                    {location.state && location.state.quote.client.city}
                  </td>
                </tr>
                <tr>
                  <td>{t('FORMS.CommentsLabel')}</td>
                  <td>{location.state && location.state.quote.note}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>{generateSites()}</Row>
      </Row>

      {location.state.quote.status === 4 && (
        <Row>
          <Col className="text-right">
            <button className="btn fadedButton" onClick={() => downloadQuote(location.state.quote.crmOpportunityId)}>
              {t('BUTTONS.DOWNLOAD')}
            </button>
          </Col>
        </Row>
      )}

      <Modal show={modalIsOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Issue ticket for #{modalId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <Row>
            <Col md={4}>Tipo di richiesta:</Col>
            <Col md={8}>
              <select width={33} value={issueType} onChange={(e) => { setIssueType(e.target.value); }}>
                {["", "Linea down", "Degrado linea", "Mancata autenticazione", "Cessazione", "Altro"].map((v, k) => (
                  <option key={`issue_type-${k}`} value={v}>{v}</option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={4}>Descrizione del problema:</Col>
            <Col md={8}>
              <textarea rows={5} cols={28} value={issueDescr} onChange={(e) => { setIssueDescr(e.target.value); }} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-secondary" onClick={hideModal}>Cancel</button>
          <button className="btn btn-sm btn-success" onClick={confirmModal}>Create</button>
        </Modal.Footer>
      </Modal>
    </Container >
  );
}

export default ConfirmQuote;
