import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import Modale from '../components/Modal';
import { render } from '@testing-library/react';
import Select from 'react-select';
import Countries from './countries';
import regioni from '../assets/Regioni.json';
import countries from '../assets/Countries.json';
import Utils from '../utils/Utils';
import { toast } from 'react-toastify';
import '../scss/components/Registration.scss';


const Registration = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [vatPrefix, setVatPrefix] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [selectedRegion, setSelectedRegion] = useState(null);

  var lang = localStorage.getItem("i18nextLng");
  
  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    var vatValidity = null;

    Utils.checkVat(vatPrefix.value, form.vatNumber.value).then((response) => {
      vatValidity = response;

      if (form.checkValidity()) {
        RestService.post({
          url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.outsideRegistration}`,
          headers: { 'Content-Type': 'application/json' },
          body: {
            name: form.CompanyName.value,
            vatNumber: `${vatPrefix.value}${form.vatNumber.value}`,
            asNumber: form.asNumber.value,
            phone: form.Phone.value,
            enabled: false,
            city: form.City.value,
            state: form.State.value,
            region: selectedRegion.it,
            country: selectedCountry?.it,
            countryCode: form.CountryCode.value,
            street: form.Street.value,
            postalCode: form.PostalCode.value,
            referenceEmail: form.Email.value,
            referencePhone: form.Phone.value,
            referenceName: form.refName.value,
            referenceSurname: form.refSurname.value,
            regionCode: selectedRegion.id
          }
        }).then((res) => {
          if (res.status > 199 && res.status < 300) {
            render(
              <Modale
                titleContent={`${t('REGISTRATION.MODAL.RegistrationSuccessHead')}`}
                bodyContent={`${t('REGISTRATION.MODAL.RegistrationSuccessBody')}`}
                vatValid={vatValidity}
                vatMessage={t('OTHERS.InvalidVATmessage')}
              />
            );
          } else {
            res.json().then((statusText) => {
              console.log(t(`ERRORS.${statusText.error}`));

              var errorMessage = t(`ERRORS.${statusText.error}`);
              
            render(
              <Modale
                titleContent={`${t('NOTIFICATIONS.NotificationSomethingWrong')}`}
                bodyContent={errorMessage !== `ERRORS.${statusText.error}` ?  errorMessage : t('REGISTRATION.MODAL.RegistrationFailedBody')}
                vatValid={vatValidity}
                vatMessage={t('OTHERS.InvalidVATmessage')}
              />
            );
            })
          }
        });
      } else {
        toast.warning(`${t('NOTIFICATIONS.NotificationMissingFields')}`);
      }
    });
  };

  const handleCountrySelection = (value) => {
    setSelectedCountry(value[`${lang}`]);
    setCountryCode(value.alpha2);
    if(value[`${lang}`] !== "Italia" || value[`${lang}`] !== "Italy"){
      setSelectedRegion({
        it: "Estero",
        en: "Abroad (Not in Italy)",
        id: "21",
        zona: "Estero"
      })
    }
  }

  return (
    <Form noValidate validated={validated} onSubmit={HandleSubmit} className="px-5">
    <Row className="item-center mt-5 mb-3"> 
      <h2 className='m-4 pinkText' style={{fontWeight: '700', fontSize: '24px'}}>{t('REGISTRATION.FORM.Heading')}</h2>
    </Row>
      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="12" controlId="CompanyName">
          <Form.Label className="float-start Registration_labels ">
            {t('FORMS.CompanyNameLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control required type="text" placeholder={`${t('FORMS.CompanyNamePlaceholder')}`} />
        </Form.Group>
      </Row>

      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="6" controlId="vatNumber">
          <Form.Label className="float-start Registration_labels">
            {t('FORMS.VatLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <InputGroup>
            <Select
              options={Countries}
              className="col-md-3"
              placeholder={'CODE'}
              value={vatPrefix}
              onChange={(e) => setVatPrefix(e)}
            />
            <Form.Control required type="text" placeholder={`${t('FORMS.VatPlaceholder')}`} style={{marginLeft: '5px'}}/>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="asNumber">
          <Form.Label className="float-start Registration_labels">{t('FORMS.AsnLabel')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.AsnPlaceholder')} />
        </Form.Group>
      </Row>
      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="8" controlId="Street">
          <Form.Label className="float-start Registration_labels">{t('FORMS.Street')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.StreetPlaceholder')} />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="PostalCode">
          <Form.Label className="float-start Registration_labels">{t('FORMS.PostalCode')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.PostalCodePlaceholder')} />
        </Form.Group>
      </Row>

      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="8" controlId="Country">
          <Row>
            <Form.Label className="float-start Registration_labels">{t('FORMS.Country')}</Form.Label>
          </Row>
          <Row>
            <Select
                name="selectSubTag"
                id="selectSubTag"
                value={selectedCountry?.[`${lang}`]}
                options={countries}
                getOptionLabel ={(option)=> option[`${lang}`]}
                getOptionValue ={(option)=> option[`${lang}`]}
                onChange={handleCountrySelection}
                placeholder="Select a country"
              />  
          </Row> 
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="CountryCode">
          <Form.Label className="float-start Registration_labels">{t('FORMS.CountryCode')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.CountryCodePlaceholder')} value={countryCode.toUpperCase()}/>
        </Form.Group>
      </Row>

      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="4" controlId="City">
          <Form.Label className="float-start Registration_labels">{t('FORMS.City')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.CityPlaceholder')} />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="Region">
          <Row>
            <Form.Label className="float-start Registration_labels">{t('FORMS.Region')}</Form.Label>
          </Row>
          <Row>
            <Select
              name="selectSubTag"
              id="selectSubTag"
              value={selectedRegion}
              options={regioni}
              getOptionLabel ={(option)=> option?.[`${lang}`]}
              getOptionValue ={(option)=> option.id}
              onChange={(e) => setSelectedRegion(e)}
              placeholder="Select a region"
            />  
          </Row>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="State">
          <Form.Label className="float-start Registration_labels">{t('FORMS.State')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.StatePlaceholder')} />
        </Form.Group>
      </Row>

      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="6" controlId="refName">
          <Form.Label className="float-start Registration_labels ">
            {t('FORMS.ReferenceNameLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control required type="text" placeholder={`${t('FORMS.ReferenceNamePlaceholder')}`} />
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="refSurname">
          <Form.Label className="float-start Registration_labels">
            {t('FORMS.ReferenceSurname')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control required type="text" placeholder={`${t('FORMS.ReferenceSurnamePlaceholder')}`} />
        </Form.Group>
      </Row>

      <Row className="mb-1 mx-3">
        <Form.Group as={Col} md="6" controlId="Phone">
          <Form.Label className="float-start Registration_labels ">
            {t('FORMS.ReferencePhoneLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control required type="tel" placeholder={`${t('FORMS.ReferencePhonePlaceholder')}`} />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="Email">
          <Form.Label className="float-start Registration_labels">
            {t('FORMS.ReferenceEmailLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control required type="email" placeholder={`${t('FORMS.ReferenceEmailPlaceholder')}`} />
        </Form.Group>
      </Row>
      <Row>
        <div className="btn Registration_Button_Container">
          <Button type="submit" variant="btn" className="Registration_Button_Container_button">
            {t('BUTTONS.APPLY')}
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default Registration;
