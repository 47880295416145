import { Modal, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import '../scss/components/Modal.scss';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function CompanyChangeStatusModal({ setShowDeleteModal, data }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const deleteRecord = () => {
    RestService.put({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getPartners}`,
      body: {
        id: data.id,
        name: data.name,
        vatNumber: data.vatNumber,
        asNumber: data.asNumber,
        email: data.email,
        phone: data.phone,
        //enabled: data.enabled === true ? false : true,
        enabled: !data.enabled,
        createdBy: data.createdBy,
        editedBy: data.editedBy,
        dateInserted: data.dateInserted,
        dateEdited: data.dateEdited,
        dateDeleted: data.dateDeleted,
        deleted: data.deleted,
        salesAccountId: data.salesAccountId,
        city: data.city,
        state: data.state,
        region: data.region,
        country: data.country,
        countryCode: data.countryCode,
        street: data.street,
        postalCode: data.postalCode,
        contractNumber: data.contractNumber
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status > 199 && res.status < 300) {
        toast.success(`${data.name} ${t('NOTIFICATIONS.NotificationEdited')}`);
        setShow(false);
        setShowDeleteModal(true);
      } else {
        toast.error(`${data.name} ${t('NOTIFICATIONS.NotificationNotEdited')} (${res.status})`);
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={false}
        onHide={() => {
          setShow(false);
          setShowDeleteModal(false);
        }}
        className="NewQuoteConfirmModal">
        <Modal.Header closeButton>
          <span>{t('MODALS.ConfirmationHeading')}</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                { data.enabled === false 
                    ? t('CHANGE_COMPANY_STATUS.Activate') 
                    : t('CHANGE_COMPANY_STATUS.Deactivate')
                } <b>{data.name}</b>?
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="fadedBorderButton p-2"
                onClick={() => {
                  setShow(false);
                  setShowDeleteModal(false);
                }}>
                {t('MODALS.BackButton')}
              </button>
            </Col>
            <Col>
              <button className="fadedButton p-2" onClick={() => deleteRecord()}>
                {t('MODALS.ProceedButton')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CompanyChangeStatusModal;
