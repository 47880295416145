import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation /*, Link*/ } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import DisablePricebookItemModal from './DisablePricebookItemModal';

import '../scss/views/managepricebooks.scss';

function AddPricebookForm() {
    const [validated, setValidated] = useState(false);
    const [isEditing, setisEditing] = useState(false);
    const [minDateFrom, setMinDateFrom] = useState(false);
    const [feasibility, setFeasibility] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [change, setChange] = useState(false);
    const [redirect, setRedirect ] = useState(false);
    const [addingForPartner, setAddingForPartner] = useState(false);
    const [portalCode, setPortalCode ] = useState(null);
    const [pricebookList, setPricebookList ] = useState(null);
    const [selectedPricebook, setSelectedPricebook ] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const location = useLocation();
    const { t } = useTranslation();
    
    useEffect(() => {
        
        if(location.state?.uploadPartnerPricebook){
            RestService.get({url: `${ConfigHandler.getConfig().api.baseUrl}/pricebook/portal`})
            .then((response) => response.json()
            .then((data) => ({data: data }))
            .then((res) => {
                setPricebookList(res.data);
            }));
        }

        if(location.state?.selectedPricebook){
            setSelectedPricebook(location.state.selectedPricebook)
        }

    }, [location.state.selectedPricebook, location.state?.uploadPartnerPricebook])
    
    const categorie = [
        { value: 'ACCESSO', name: 'ACCESSO'}, 
        { value: 'ANTIDDOS', name: 'ANTIDDOS'},
        { value: 'COLOCATION', name: 'COLOCATION'}, 
        { value: 'CROSS CONNECTION', name: 'CROSS CONNECTION'}, 
        { value: 'DATA CENTER INTERCONNECT', name: 'DATA CENTER INTERCONNECT'}, 
        { value: 'DDOS MITIGATION', name: 'DDOS MITIGATION'}, 
        { value: 'ENNI', name: 'ENNI'},
        { value: 'HYPERLAN', name: 'HYPERLAN'}, 
        { value: 'IP TRANSIT', name: 'IP TRANSIT'}, 
        { value: 'NOLEGGIO HW', name: 'NOLEGGIO HW'}, 
        { value: 'PRESALES', name: 'PRESALES'},
        { value: 'PROMO', name: 'PROMO'},
        { value: 'REMOTE PEERING', name: 'REMOTE PEERING'}, 
        { value: 'TRASPORTO', name: 'TRASPORTO'}, 
        { value: 'VIRTUAL PoP', name: 'VIRTUAL PoP'}
    ]

    const provider = [
        { value: 'FT', name: 'FT'},
        { value: 'TIM', name: 'TIM'}, 
        { value: 'FASTWEB', name: 'FASTWEB'}, 
        { value: 'OPEN FIBER', name: 'OPEN FIBER'},
        { value: 'EOLO', name: 'EOLO'},
        { value: 'ATOMO', name: 'ATOMO'},
        { value: 'OPNET', name: 'OPNET'}
    ]
    
    
    /**
     * "If the user is editing a pricebook, and the selected pricebook has a category, and the selected
     * pricebook's category is equal to the value of the current iteration of the map function, then push
     * an option element to the categoriesOptions array with the selected attribute and the key, value, and
     * name of the current iteration of the map function. Otherwise, push an option element to the
     * categoriesOptions array with the key, value, and name of the current iteration of the map function."
     * 
     * I'm not sure if that's the best way to do it, but it works.
     * 
     * I hope this helps someone else.
     * @returns An array of options.
     */
    const getCategories = () =>{
        var categoriesOptions = [];

        categorie.map((value, i) => {
            if(isEditing && selectedPricebook.category === value.value){
                categoriesOptions.push(<option selected key={value.value} value={value.value}>{value.name}</option>)
            } else {
                categoriesOptions.push(<option key={value.value}  value={value.value}>{value.name}</option>)
            }
        })

        return categoriesOptions;
    }

    /**
     * It's a function that returns an array of options for a select element.
     * @returns An array of options.
     */
    const getProviders = () =>{
        var providerOptions = [];

        provider.map((value, i) => {
            if(isEditing && selectedPricebook.provider === value.value){
                providerOptions.push(<option key={value.value} selected value={value.value}>{value.name}</option>)
            } else {
                providerOptions.push(<option key={value.value} value={value.value}>{value.name}</option>)
            }
        })

        return providerOptions;
    }
    
    const getPortalCodes = () => {
        var portalCodeOptions = [];
        (pricebookList != null) && (
            pricebookList.map((value, i) => {
                if(isEditing && location.state.selectedPricebook?.portalCode && location.state.selectedPricebook.portalCode === value.portalCode){
                    portalCodeOptions.push(<option key={value.portalCode} selected value={value.portalCode} id={i}>{value.portalCode}</option>)
                } else {
                    portalCodeOptions.push(<option key={value.portalCode} value={value.portalCode} id={i}>{value.portalCode}</option>)
                }
            })
        )

        return portalCodeOptions;
    }

    const handleChangePortalCode = (event) => {
        setSelectedPricebook(pricebookList[event.id]);
        setAddingForPartner(true);
    }
    
    useEffect(() => {

        /* Setting the state of the component. */
        if(location.state && location.state.selectedPricebook){
            setisEditing(true);
            setFeasibility(location.state.selectedPricebook.feasability)
            if(location.state?.selectedPricebook.dateFrom != null) {
                var date1 = FormDate(location.state.selectedPricebook.dateFrom);
                setDateFrom(date1)
                var date2 = FormDate(location.state.selectedPricebook.dateTo);
                setDateTo(date2)
            }
        }

        /* Setting the minimum date to the current date plus one day. */
        var date = new Date(Date.now())
            date.setUTCSeconds(0, 0);
        setMinDateFrom(date.toISOString().replace(/:00.000Z/, ""))
    }, [location.state])

    const onSwitchAction = () => {
        setFeasibility(!feasibility);
    };

    /**
     * It takes a date, sets the seconds to 0, and returns the date in ISO format.
     * @param date - The date you want to format.
     * @returns A string in the format of "YYYY-MM-DDTHH:MM:SS"
     */
    
    const FormDate = (date) => {
        var formattedDate = new Date(date);
        formattedDate.setSeconds(0, 0);
        formattedDate = formattedDate.toISOString().replace(/:00.000Z/, "")
        return formattedDate; 
    }
    
    /**
     * It takes the form data, and sends it to the server.
     * @param event - the event that is triggered when the form is submitted
     */
    
    const HandleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();  
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
    

        var finalPartner = null;

        if(location.state.uploadPartnerPricebook?.id){
            finalPartner = location.state.uploadPartnerPricebook.id;
        }

        if (!isEditing && form.checkValidity()){
            var dateFromToSend = new Date(form.dateFrom.value);
            var dateToToSend = new Date(form.dateTo.value);

            if(!isNaN(dateFromToSend)){
                dateFromToSend = `${dateFromToSend.getFullYear()}-${dateFromToSend.getMonth() + 1}-${dateFromToSend.getDate()} ${dateFromToSend.getHours()}:${dateFromToSend.getMinutes()}:${dateFromToSend.getSeconds()}`
            } else {
                dateFromToSend = null;
            }

            if(!isNaN(dateToToSend)){
                dateToToSend = `${dateToToSend.getFullYear()}-${dateToToSend.getMonth() + 1}-${dateToToSend.getDate()} ${dateToToSend.getHours()}:${dateToToSend.getMinutes()}:${dateToToSend.getSeconds()}`
            } else {
                dateToToSend = null;
            }

            RestService.post({
                url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebokTable}?partnerId=${finalPartner === null ? '' : finalPartner}`,
                body: {
                    "category": form.category.value,
                    "code": form.code.value,
                    "description": form.description.value,
                    "nrc": form.NRC.value,
                    "mrc": form.MRC.value,
                    "feasability": feasibility,
                    "duration": form.duration.value,
                    "provider": form.provider.value,
                    "sapNrcCode": form.sapNrcCode.value,
                    "sapRcCode": form.sapRcCode.value,
                    "accountDiscountThreshold": form.accountDiscountThreshold.value,
                    "thresholdDiscountDir": form.thresholdDiscountDir.value,
                    "fixedNrCost": form.fixedNrCost.value,
                    "mrGgPpCost": form.mrGgPpCost.value,
                    "mrInfraCost": form.mrInfraCost.value,
                    "dateFrom": dateFromToSend,
                    "dateTo": dateToToSend,
                    "partnerId": parseInt(finalPartner)
                }
            }).then((res) => {
                if (res.status > 199 && res.status < 300) {
                  toast.success(`${t('NOTIFICATIONS.NotificationdAdded')}`);
                  setRedirect(true);
                } else {
                    res.json().then((statusText) => {
                    toast.error(
                        <>
                        {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                        <small>
                            <i>{statusText.error}</i>
                        </small>
                        </>
                    );
                    });
                }
            });
        } else if(isEditing && form.checkValidity()){
            RestService.put({
                url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebokTable}?partnerId=${finalPartner === null ? '' : finalPartner}`,
                body: {
                    "id": location.state.selectedPricebook.id,
                    "category": form.category.value,
                    "code": form.code.value,
                    "portalCode": form.portalCode.value,
                    "description": form.description.value,
                    "nrc": form.NRC.value,
                    "mrc": form.MRC.value,
                    "feasability": feasibility,
                    "provider": form.provider.value,
                    "duration": form.duration.value,
                    "sapNrcCode": form.sapNrcCode.value,
                    "sapRcCode": form.sapRcCode.value,
                    "accountDiscountThreshold": form.accountDiscountThreshold.value,
                    "thresholdDiscountDir": form.thresholdDiscountDir.value,
                    "fixedNrCost": form.fixedNrCost.value,
                    "mrGgPpCost": form.mrGgPpCost.value,
                    "mrInfraCost": form.mrInfraCost.value,
                    "partnerId": parseInt(finalPartner)
                }
            }).then((res) => {
                if (res.status > 199 && res.status < 300) {
                  toast.success(`${form.portalCode.value} ${t('NOTIFICATIONS.NotificationEdited')}`);
                  setRedirect(true);
                } else {
                    res.json().then((statusText) => {
                        toast.error(`${form.portalCode.value} ${t('NOTIFICATIONS.NotificationNotEdited')} (${statusText.reason})`);
                    })
                }
              })
        } else {
            toast.warning('Something is missing...')
        }
    }

    /**
     * If the user is editing a pricebook, and the pricebook is associated with a partner, then return
     * the translated string "Edit pricebook for {companyName}".
     * 
     * If the user is editing a pricebook, and the pricebook is not associated with a partner, then
     * return the translated string "Edit pricebook".
     * 
     * If the user is not editing a pricebook, and the pricebook is associated with a partner, then
     * return the translated string "Upload pricebook for {companyName}".
     * 
     * If the user is not editing a pricebook, and the pricebook is not associated with a partner, then
     * return the translated string "Upload pricebook".
     * @returns A function that returns a JSX element.
     */
    const generateTitle = () =>{
        var partner = location.state?.uploadPartnerPricebook || null;
        
        if(partner != null && !isEditing){
            return (<h2 className='fadedText'>{t('PRICEBOOK.FORM.HeadingPartner', {companyName: location.state.uploadPartnerPricebook.name})}</h2>)
        } else if(!isEditing){
            return (<h2 className='fadedText'>{t('PRICEBOOK.FORM.Heading')}</h2>)
        } else if (isEditing && partner != null){
            return (<h2 className='fadedText'>{t('PRICEBOOK.FORM.HeadingPartnerEdit', {companyName: location.state.uploadPartnerPricebook.name})}</h2>)
        } else if(isEditing){
            return (<h2 className='fadedText'>{t('PRICEBOOK.FORM.HeadingEdit')}</h2>);
        }
    }

  return (
    <Container fluid>
        {
            showModal && <DisablePricebookItemModal setShowModal={setShowModal} data={selectedPricebook} partner={location.state?.uploadPartnerPricebook ? location.state.uploadPartnerPricebook.name : null} />
        }
        {
            redirect && <Redirect to='/admin/pricebooktable' />
        }
        <Row className='mb-3'>
            <Col md={6}>
                {generateTitle()}
            </Col>
            <Col md={6} className='item-right'>
                {
                    (isEditing)&&(<button className='btn fadedButton' onClick={() => {setShowModal(true)}}>{t('BUTTONS.DISABLE')}</button>) 
                }
            </Col>
        </Row>
        <Row>
            <Form noValidate validated={validated} onSubmit={HandleSubmit} id='AddPricebookForm'>
                <Row  className='mb-3 mx-3'>
                    <Form.Group as={Col} md="4" controlId="category">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.CategoryLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Select required>
                           {getCategories()}
                        </Form.Select>
                        { /* <Form.Control required type="text" placeholder={t('PRICEBOOK.FORM.CategoryPlaceholder')} defaultValue={isEditing ? location.state?.selectedPricebook.category : ''} /> */}
                    </Form.Group>
                    <Form.Group as={Col} md="8" controlId="description">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.DescriptionLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder={t('PRICEBOOK.FORM.DescriptionPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.description : ''}/>
                    </Form.Group>
                </Row>
                <Row className="mb-3 mx-3">
                    <Form.Group as={Col} md="3" controlId="portalCode">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.PortalCodeLabel')} {(location.state?.uploadPartnerPricebook)&&(<span style={{color: '#F00'}}>*</span>)}</Form.Label>
                        {
                            location.state?.uploadPartnerPricebook
                                ? <Form.Select required disabled={isEditing} onChange={(event) => handleChangePortalCode(event.target.options[event.target.selectedIndex])}>{getPortalCodes()}</Form.Select>
                                : <Form.Control disabled type="text" defaultValue={isEditing || addingForPartner ? selectedPricebook.portalCode : ''}/>
                        }
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="code">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.CodeLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder={t('PRICEBOOK.FORM.CodePlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.code : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="feasability">
                        <Row>
                            <Form.Label className="float-start"> {t('PRICEBOOK.FORM.FeasibilityLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                            <Form.Switch
                                onChange={onSwitchAction}
                                id="custom-switch"
                                checked={feasibility}
                                label={change ? t('PRICEBOOK.FORM.FeasibilityPlaceholderTrue') : t('PRICEBOOK.FORM.FeasibilityPlaceholderFalse') }
                                className={`mx-3 ${change ? 'active_switch' : 'deactive_switch'}`}
                                onClick={() => { setChange(!change); }}
                            />
                        </Row>
                    </Form.Group>
                </Row>
                <Row className="mt-5 mb-2 mx-3">
                    <h5 className='subTitle'>{t('PRICEBOOK.FORM.SubHeading1')}</h5>
                </Row>
                <Row className="mb-3 mx-3">
                    <Form.Group as={Col} md="3" controlId="NRC">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.NRCLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.NRCPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.nrc : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="MRC">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.MRCLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.MRCPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.mrc  : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="accountDiscountThreshold">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.AccountDiscountThresholdLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.AccountDiscountThresholdPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.accountDiscountThreshold : 5.00}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="thresholdDiscountDir">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.ThresholdDiscountDirLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.ThresholdDiscountDirPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.thresholdDiscountDir : 15.00}/>
                    </Form.Group>
                </Row>
                <Row className="mb-3 mx-3">
                    <Form.Group as={Col} md="4" controlId="fixedNrCost">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.FixedNrCostLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.FixedNrCostPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.fixedNrCost  : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="mrGgPpCost">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.MrGgPpCostLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.MrGgPpCostPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.mrGgPpCost : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="mrInfraCost">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.MrInfraCostLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step=".01" placeholder={t('PRICEBOOK.FORM.MrInfraCostPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.mrGgPpCost : ''}/>
                    </Form.Group>
                </Row>
                <Row className="mt-5 mb-2 mx-3">
                    <h5 className='subTitle'>{t('PRICEBOOK.FORM.SubHeading2')}</h5>
                </Row>
                    {
                    !isEditing && (
                        <Row className="mb-3 mx-3">
                            <Form.Group as={Col} md="6" controlId="dateFrom">
                                <Form.Label className="float-start"> {t('PRICEBOOK.FORM.DateFromLabel')} </Form.Label>
                                <Form.Control type="datetime-local" min={minDateFrom.toString()} defaultValue={minDateFrom.toString()}/>
                                <Form.Control.Feedback type="invalid">
                                    {t('PRICEBOOK.FORM.InvalidDateMessage')} {new Date(minDateFrom).toString()}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="dateTo">
                                <Form.Label className="float-start"> {t('PRICEBOOK.FORM.DateToLabel')} </Form.Label>
                                <Form.Control type="datetime-local" min={minDateFrom.toString()} defaultValue={minDateFrom.toString()}/>
                                <Form.Control.Feedback type="invalid">
                                    {t('PRICEBOOK.FORM.InvalidDateMessage')} {new Date(minDateFrom).toString()}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    )
                }
                <Row className="mb-3 mx-3">
                    <Form.Group as={Col} md="12" controlId="duration">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.DurationLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="number" step="1" placeholder={t('PRICEBOOK.FORM.DurationPlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.duration : ''}/>
                    </Form.Group>
                </Row>
                <Row className="mt-5 mb-2 mx-3">
                    <h5 className='subTitle'>{t('PRICEBOOK.FORM.SubHeading3')}</h5>
                </Row>
                <Row className="mb-3 mx-3">
                    <Form.Group as={Col} md="3" controlId="sapNrcCode">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.SapNrcCodeLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder={t('PRICEBOOK.FORM.SapNrcCodePlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.sapNrcCode : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="sapRcCode">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.SapRcCodeLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Control required type="text" placeholder={t('PRICEBOOK.FORM.SapRcCodePlaceholder')} defaultValue={isEditing || addingForPartner ? selectedPricebook.sapRcCode : ''}/>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="provider">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.ProviderLabel')} <span style={{color: '#F00'}}>*</span></Form.Label>
                        <Form.Select required>
                           {getProviders()}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="partnerId">
                        <Form.Label className="float-start"> {t('PRICEBOOK.FORM.PartnerLabel')} </Form.Label>
                        <Form.Control disabled type="text" value={location.state?.uploadPartnerPricebook ? location.state.uploadPartnerPricebook.name : t('PRICEBOOK.FORM.PartnerPlaceholderForDefault')} />
                    </Form.Group>
                </Row>
                <Row className='text-center mt-5'>
                    <Col md={{ span: 4, offset: 4 }}>
                        <button type="submit" className="fadedButton btn">
                            {isEditing ? t('BUTTONS.EDIT') : t('BUTTONS.ADD')}
                        </button>
                    </Col>
                </Row>
            </Form>
        </Row>
    </Container>
  )
}

export default AddPricebookForm