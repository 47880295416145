import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../scss/components/Sidebar.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setSidebarStatus } from '../store/actions/sidebar';
import Icon from '@mui/material/Icon';
import { Link, useLocation } from 'react-router-dom';
import RouteWithSubRoutes from '../components/RouteWithSubRoutes';
import { Container, Row, Col } from 'react-bootstrap';
import ConfigHandler from '../config';
import { AuthHandler } from '../utils/AuthHandler';
import { useTranslation } from 'react-i18next';

function Sidebar() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  let location = useLocation();
  const { t, i18n } = useTranslation();

  const click = (mSlug, mTitle, sSlug, sTitle) => {
    dispatch(setSidebarStatus(mSlug, mTitle, sSlug, sTitle));
  };

  const userRole = AuthHandler.getUserData();

  /**
   * It returns a list of links to submenu items, if the user has the correct role.
   * @param item - {
   * @returns An array of JSX elements.
   */

  const getSubMenu = (item) => {
    return item.subMenu.map((sub_val, sub_key) => {
      var visibilitySubItem = true;
      sub_val.roles &&
        (sub_val.roles.toString().toLowerCase().includes(userRole.role[0].toLowerCase())
          ? (visibilitySubItem = true)
          : (visibilitySubItem = false)
        );

      if (visibilitySubItem) {
        return (
          <Link to={sub_val.link} key={`submenuItem_${sub_key}`}>
            <li
              className={`subListItem ${state.root.sidebar.sub_slug === sub_val.slug ? 'active' : null}`}
              onClick={() => click(item.slug, item.title[i18n.language], sub_val.slug, sub_val.title[i18n.language])}>
              {sub_val.title[i18n.language]}
            </li>
          </Link>
        );
      }
    });
  };

  return (
    <Col md="auto">
      <div className="Sidebar">
        <ul className="SidebarList">
          {
            ConfigHandler.getConfig().sidebardata.menus.map((val, key) => {
              if (val.link.startsWith("https://") == true) {
                return (
                  <Fragment key={`${val.slug}-${key}`}>
                    <Link to={{ pathname: val.link }} key={`linkto_${val.link}`} target="_blank">
                      <li className="ListItem">
                        <div id="icon">
                          <Icon>{val.icon}</Icon>
                        </div>
                        <div id="title">{val.title[i18n.language]}</div>
                      </li>
                    </Link>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={`${val.slug}-${key}`}>
                    <Link to={val.link} key={`linkto_${val.link}`}>
                      <li
                        className={`ListItem ${(state.root.sidebar.active_slug === val.slug && location.pathname.includes(val.linkM)) || location.pathname === val.linkM ? 'active' : ''}`}
                        onClick={() => click(val.slug, val.title[i18n.language], null, null)}>
                        <div id="icon"><Icon>{val.icon}</Icon></div>
                        <div id="title">{val.title[i18n.language]}</div>
                        {val.subMenu !== undefined && (<div id="subTrigger">{' '}<ExpandMoreIcon />{' '}</div>)}
                      </li>
                    </Link>
                    {val.subMenu !== undefined && state.root.sidebar.active_slug === val.slug && (
                      <li>
                        <ul className="subMenuList" key={`submenu-subMenuList-${key}`}>
                          {getSubMenu(val)}
                        </ul>
                      </li>
                    )}
                  </Fragment>
                );
              }
            })
          }
        </ul>
      </div>
    </Col >
  );
}

export default Sidebar;
