import React, { useEffect, useState } from 'react';
import ConfigHandler from '../config';
import { RestService } from '../utils';

function PricebookPromoBanner() {
  const [imagesUrl, setImagesUrl] = useState([]);
  const [idxUrl, setIdxUrl] = useState(0);
  const [idxMax, setIdxMax] = useState(0);

  useEffect(() => {
    RestService.get({ url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookPromo}?` })
      .then(response => {
        response.json()
          .then((data) => ({ data: data, }))
          .then((res) => {
            if (res && res.data && res.data && Array.isArray(res.data.data) && res.data.data.length > 0) {
              setImagesUrl(res.data.data); 
              setIdxMax(res.data.limit); 
              setIdxUrl(0);
            }
          });
      })
      .catch(error => {
        console.error("Errore durante il caricamento dei dati:", error);
      });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => { setIdxUrl(idx => (idx + 1) % imagesUrl.length); }, 7500);
    return () => clearInterval(interval);
  }, [imagesUrl]);

  const descr = "Promo " + (idxUrl+1).toString() + "/" + idxMax.toString();
  return (imagesUrl.length>0) ? (
    <div className="position-relative mb-5">
      <span className={`position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill fs-6 ${idxMax>1?'visible':'invisible'}`}>
          {idxUrl+1} / {idxMax}
      </span>
      <a rel="noreferrer" title={descr + " - click for info"} target="_blank" href={imagesUrl[idxUrl].description.replace(/\.\w+$/, '.pdf')}>
        <img className="w-100 m-0 rounded rounded-3" src={imagesUrl[idxUrl].description} alt={descr} />
      </a>
    </div>
  ) : "";
};

/*
function PricebookPromoBanner() {
  const [imageUrl, setImageUrl] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  useEffect(() => {
    RestService.get({ url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookPromo}?` })
      .then(response => {
        response.json()
          .then((data) => ({ data: data, }))
          .then((res) => {
            if (res && res.data && res.data && Array.isArray(res.data.data) && res.data.data.length > 0) {
              setImageUrl(res.data.data[0].description);
              setLinkUrl(res.data.data[0].description.replace(/\.\w+$/, '.pdf'));
            }
          });
      })
      .catch(error => {
        console.error("Errore durante il caricamento dei dati:", error);
      });
  }, []);
  return imageUrl ? (<div><a rel="noreferrer" title="PROMO - click for info" target="_blank" href={linkUrl}><img className="w-100 mb-5 rounded rounded-3" src={imageUrl} alt="Promo" /></a></div>) : "";
};
*/

export default PricebookPromoBanner;
