import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { RestService } from '../utils';
import { useSelector } from 'react-redux';
import ConfigHandler from '../config';
import AsyncSelect from 'react-select/async';
import { useLocation, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AuthHandler } from '../utils/AuthHandler';
import Icon from '@mui/material/Icon';
import DeleteUserModal from '../components/DeleteUserModal';
import ResetPasswordConfirmation from '../components/ResetPasswordConfirmation';
import '../scss/views/AdminNewUser.scss';

const AdminNewUser = (props) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const state = useSelector((state) => state);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValueRole, setSelectedValueRole] = useState('');
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [change, setChange] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const [roleValues, setRoleValues] = useState([]);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [ShowDeleteModal, setShowDeleteModal] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);
  const [ShowPwdResetModal, setShowPwdModal] = useState(false);

  useEffect(() => {
    getRoleValues();
    if (AuthHandler.isAuth()) {
      const userRole = AuthHandler.getUserData();
      if (userRole.role[0].toLowerCase() != 'admin') {

        //pre-set partner
        RestService.get({
          url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getMe}`,
        }).then((response) =>
          response
            .json()
            .then((data) => ({
              data,
            }))
            .then((res) => {
              setSelectedValue(res.data.partner);
            }),
        );

        setDisabledSelect(true);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state != undefined) {
      setIsEditing(true);
      setSelectedValueRole(location.state.data.roleId);
      setSelectedValue(location.state.data.partner);
      if (location.state.data.enabled === true) {
        setChange(true);
        setIsSwitchOn(true);
      }
    }
  }, [location.state]);

  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (!isEditing && form.checkValidity()) {
      RestService.post({
        url: `${ConfigHandler.getConfig().api.baseUrl}${
          ConfigHandler.getConfig().api.path.registration
        }`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          name: form.FirstName.value,
          surname: form.LastName.value,
          username: form.UserInformation.value,
          enabled: isSwitchOn,
          roleId: selectedValueRole,
          partnerId: selectedValue.id,
        }
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          toast.success(`${t('NOTIFICATIONS.NotificationdAdded')}`);
          setToRedirect(true);
        } else {
          res.json().then((statusText) => {
            toast.error(
              <>
                {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                <small>
                  <i>{statusText.error}</i>
                </small>
              </>
            );
          });
        }
      });
    } else if (isEditing && form.checkValidity()) {
      RestService.put({
        url: `${ConfigHandler.getConfig().api.baseUrl}${
          ConfigHandler.getConfig().api.path.registration
        }`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          id: location.state.data.id,
          name: form.FirstName.value,
          surname: form.LastName.value,
          username: form.UserInformation.value,
          enabled: isSwitchOn,
          roleId: selectedValueRole,
          partnerId: selectedValue.id,
        }
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          toast.success(`${form.UserInformation.value} ${t('NOTIFICATIONS.NotificationEdited')}`);
          setToRedirect(true);
        } else {
          toast.error(`${form.UserInformation.value} ${t('NOTIFICATIONS.NotificationNotEdited')} (${res.status})`);
        }
      });
    }
  };
  /* ## AsyncSelect part  ## */
  // handle selection company
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  // handle selection role
  const handleChangeRole = (event, index) => {
    setSelectedValueRole(event.target.value);
  };
  // load options using API call User Information
  const loadOptions = (inputValue, callback) => {
    return RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getCompanies
      }?q=${inputValue}&offset=0&limit=40&order=name:asc`
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data
        }))
        .then((res) => {
          callback(res.data.data);
          return res.data.data;
        })
    );
  };

  const getRoleValues = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getRoles}`
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data
        }))
        .then((res) => {
          var rolesValues = [];
          if (res.data && res.data.length > 0) {
            for (var i = 0; i <= res.data.length; i++) {
              if (res.data[i]) {
                rolesValues.push(
                  <option value={res.data[i].id} key={res.data[i].id}>
                    {res.data[i].description}
                  </option>
                );
              }
            }
          }
          setRoleValues(rolesValues);
        })
    );
  };

  /* ## Toggle switch button## */
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  /* ## I gave styles of the AsyncSelect part inside the component because the documentation shows it like this  ## */
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid  rgb(168, 168, 168)'
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: state.isFocused && 'none',
      '&:focus-within': {
        border: '1px solid #ed3a7f',
        boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)'
      },
      '&:invalid': {
        border: '1px solid green'
      }
    })
  };
  return (
    <>
      {ShowDeleteModal && (
        <DeleteUserModal
          setShowDeleteModal={setShowDeleteModal}
          data={location.state.data}
          setToRedirect={setToRedirect}
        />
      )}
      {ShowPwdResetModal && (
        <ResetPasswordConfirmation
          setShowPwdModal={setShowPwdModal}
          data={location.state.data}
          setToRedirect={setToRedirect}
        />
      )}
      {toRedirect && <Redirect to="/admin/teammembers" />}
      <Form
        noValidate
        validated={validated}
        onSubmit={HandleSubmit}
        className="px-3 mx-3 AdminNewUSer">
        <Row>
          <Col md={6}>
            <p className="h2 AdminNewUSer__MainTitle">
              {isEditing
                ? `${t('NEWUSER.EditingTitleLabel')} ${location.state.data.name} ${location.state.data.surname}`
                : `${t('NEWUSER.TitleLabel')}`}
            </p>
          </Col>
          <Col md={6} className="item-right fadedText">
            {isEditing && (
              <>
                <span
                  style={{
                    padding: '0 1.6rem 3rem 0',
                    display: 'inline-block',
                    textAlign: 'center'
                  }}
                  onClick={() => setShowDeleteModal(true)}>
                  <Icon>delete</Icon>
                  <p>
                    <b>{t('NEWUSER.DeleteUserLabel')}</b>
                  </p>
                </span>
                <span
                  style={{
                    padding: '0 1.6rem 3rem 0',
                    display: 'inline-block',
                    textAlign: 'center'
                  }}
                  onClick={() => setShowPwdModal(true)}>
                  <Icon>lock_reset</Icon>
                  <p>
                    <b>{t('NEWUSER.ResetPasswordLabel')}</b>
                  </p>
                </span>
              </>
            )}
          </Col>
        </Row>
        <p className="h4 AdminNewUSer_subtitle">{t('NEWUSER.FORM.ContactInformationsTitle')}</p>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="6" controlId="FirstName">
            <Form.Label className="float-start AdminNewUSer_labels">
              {' '}
              {t('FORMS.NameLabel')} <span className="AdminNewUSer_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={`${t('FORMS.NamePlaceholder')}`}
              defaultValue={isEditing ? location.state.data.name : null}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="LastName">
            <Form.Label className="float-start AdminNewUSer_labels">
              {t('FORMS.SurnameLabel')} <span className="AdminNewUSer_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={`${t('FORMS.SurnamePlaceholder')}`}
              defaultValue={isEditing ? location.state.data.surname : null}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} controlId="Role">
            <Form.Label className="float-start AdminNewUSer_labels">
              {t('FORMS.RoleLabel')}
            </Form.Label>
            <Form.Select
              onChange={handleChangeRole}
              value={selectedValueRole}
              className="AdminNewUSer__AsyncSelect_role">
              <option value={t('FORMS.RoleSelect')}>{t('FORMS.RoleSelect')}</option>
              {roleValues}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* ##### CompanyName  ####### */}
        <p className="h4 AdminNewUSer_subtitle">{t('NEWUSER.FORM.UserInformationsTitle')}</p>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} controlId="UserInformation">
            <Form.Label className="float-start AdminNewUSer_labels">
              {t('FORMS.Username')}
              <span className="AdminNewUSer_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={`${t('NEWUSER.FORM.UsernamePlaceholder')}`}
              required
              defaultValue={isEditing ? location.state.data.username : null}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} controlId="CompanyType">
            <Row>
              <Form.Label className="float-start AdminNewUSer_labels">
                {t('FORMS.CompanyNameLabel')}
                <span className="AdminNewUSer_star_symbol">*</span>
              </Form.Label>
            </Row>
            <AsyncSelect
              className="AdminNewUSer__AsyncSelect_Company"
              required
              cacheOptions
              defaultOptions
              value={selectedValue}
              loadOptions={loadOptions}
              onChange={handleChange}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              styles={customStyles}
              isDisabled={disabledSelect}
            />
          </Form.Group>
          {/* ## Switch Toggle ## */}
          <Form.Switch
            onChange={onSwitchAction}
            id="custom-switch"
            checked={isSwitchOn}
            label={`${change ? t('NEWUSER.FORM.UserActive') : t('NEWUSER.FORM.UserInactive')}`}
            className={`mb-3 mt-3 mx-3 ${change ? 'active_switch' : 'deactive_switch'}`}
            onClick={() => {
              setChange(!change);
            }}
          />
          <div className="btn AdminNewUSer_Button_Container">
            <button type="submit" className="fadedButton btn">
              {isEditing ? t('BUTTONS.EDIT') : t('BUTTONS.ADD')}
            </button>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default AdminNewUser;
