import { Modal, Dropdown } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import Icon from '@mui/material/Icon';
import { AuthHandler } from '../utils/AuthHandler';
import { Link, useLocation } from 'react-router-dom';
import '../scss/components/ResponsiveMenu.scss';
import FlagIT from '../assets/flags/it.svg';
import FlagEN from '../assets/flags/gb.svg';

function ResponsiveMenu({ setShowResponsiveMenu, handleLanguage, setOpenPassword }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const userData = AuthHandler.getUserData();
  let location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  const prevLocation = useRef();
  prevLocation.current = location.pathname;

  useEffect(() => {
    setCurrentLocation(location.pathname);
    if(prevLocation.current != currentLocation){
      setShow(false);
      setShowResponsiveMenu(false);
    }
  }, [location.pathname])

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => {
          setShow(false);
          setShowResponsiveMenu(false);
        }}
        className='ResponsiveMenu'
        >
        <Modal.Header className="Modal__header" closeButton>
          <h2 className="pinkText" style={{ textAlign: 'center' }}>
            Menu
          </h2>
        </Modal.Header>
        <Modal.Body className="Modal_body">
          <Sidebar />
        <Link to="/user/profile">
          <div className="menuItem">
            <div className="icon">
              <Icon>account_circle</Icon>
            </div>
            <div className="value">{userData != null && (userData.username)} ({t('NAVIGATION.ProfileLabel')})</div>
          </div>
        </Link>

          {(userData.role[0].toLowerCase() === 'admin' || userData.role[0].toLowerCase() === 'broker') &&
            AuthHandler.isAuth() && (
            <Link to="/admin"> 
              <div className="menuItem">
                <div className="icon">
                  <Icon>admin_panel_settings</Icon>
                </div>
                <div className="value">{t('NAVIGATION.AdminLabel')}</div>
              </div>
            </Link>
            )}

          <div className="menuItem" onClick={() => {setOpenPassword(true);}}>
            <div className="icon">
              <Icon>lock_reset</Icon>
            </div>
            <div className="value">{t('NAVIGATION.ChangePasswordLabel')}</div>
          </div>

          <div className="menuItem" onClick={() => {AuthHandler.clearAuth();}}>
            <div className="icon">
              <Icon>logout</Icon>
            </div>
            <div className="value">{t('NAVIGATION.LogoutLabel')}</div>
          </div>

          <div className={localStorage.getItem('i18nextLng') == 'it' ? "menuItem active" : "menuItem"} onClick={() => handleLanguage('it')}>
            <div className="icon">
              <img src={FlagIT} style={{ width: '24px', borderRadius: '50%' }} />
            </div>
            <div className="value">{t('LANGUAGES.ItalianLabel')}</div>
          </div>

          <div className={localStorage.getItem('i18nextLng') == 'en' ? "menuItem active" : "menuItem"} onClick={() => handleLanguage('en')}>
            <div className="icon">
              <img src={FlagEN} style={{ width: '24px', borderRadius: '50%' }} />
            </div>
            <div className="value">
            {t('LANGUAGES.EnglishLabel')}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResponsiveMenu;
