import React, { Fragment } from 'react';
import '../scss/views/LoginResponsive.scss';
import Login from '../components/Login';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';

// JSX

const LoginResponsive = ({setShowForgotPasswordModal, setShowRegistration}) => {
  const { t } = useTranslation();

  return (
      <Row className='container-login g-0'>
        <Col md={6}>
        <div className="left-heading">
              <h1>{t('LOGIN.LEFTTEXT.FirstLine')}</h1>
              <h2>{t('LOGIN.LEFTTEXT.SecondLine')}</h2>
              <h2>{t('LOGIN.LEFTTEXT.ThirdLine')}</h2>
              <h3>{t('LOGIN.LEFTTEXT.FourthLine')}</h3>
            </div>
        </Col>
        <Col md={6}>
          <div className='login-form'>
          <Login
                titlepanel={`${t('LOGIN.FORM.Heading')}`}
                username={t('LOGIN.FORM.Username')}
                passwordlabel={`${t('LOGIN.FORM.Password')}`}
                incorrect={`${t('LOGIN.FORM.IncorrectPasswordLabel')}`}
                res={`${t('LOGIN.FORM.ResetIt')}`}
                log={`${t('BUTTONS.LOGIN')}`}
                forg={`${t('LOGIN.FORM.ForgotPasswordLabel')}`}
                NC={`${t('LOGIN.FORM.RegisterQuestion')}`}
                regist={`${t('LOGIN.FORM.RegisterLabel')}`}
                setShowForgotPasswordModal={setShowForgotPasswordModal}
                setShowRegistration={setShowRegistration}
              />
          </div>
        </Col>
      </Row>
  );
};
export default LoginResponsive;
