const init = (env) => {
  const enviroment = env.REACT_APP_ENV ? env.REACT_APP_ENV : "dev";
  setConfig(require("./" + enviroment + ".json"));
  //console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
};

const setConfig = (config) => {
  window.config = config;
};

const getConfig = () => {
  return window.config;
};

export default {
  init: init,
  getConfig: getConfig,
};
